import ArrayService from "@/services/ArrayService";

beforeEach(() => {
  jest.restoreAllMocks();
});

describe("ArrayService", () => {
  describe("filterBoth", () => {
    const evenNumbers = [2, 4, 6];
    const oddNumbers = [1, 3, 5];
    const isEven = (item: number) => item % 2 === 0;

    it("should return a tuple, with matching & non-matching items", () => {
      // Given
      // When
      const result = ArrayService.filterBoth(
        [...evenNumbers, ...oddNumbers],
        isEven
      );
      // Then
      expect(result).toStrictEqual([evenNumbers, oddNumbers]);
    });
    it("should return a tuple, with empty array (because there are no matching items) & non-matching items", () => {
      // Given
      // When
      const result = ArrayService.filterBoth(oddNumbers, isEven);
      // Then
      expect(result).toStrictEqual([[], oddNumbers]);
    });
  });
});
